import { Component, Input } from '@angular/core';

import { InteractActions } from '../interact.actions'
import { InteractQuestTab, InteractQuestState, IQuest } from '../../../app.state'

import * as util from '../../../services/util.service'

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-app-int-settings-nav',
  templateUrl: './interact-settings-nav.component.html',
  styleUrls: ['./interact-settings-nav.component.scss']
})
export class InteractSettingsNavComponent {
  @Input() settingsTab: InteractQuestTab
  @Input() currentQuestState: InteractQuestState
  @Input() activeQuest = <IQuest>null
  @Input() questIsOpened: boolean

  constructor(private interactActions: InteractActions) {}

  menuItemClicked(m) {
    if (m <= this.currentQuestState + 1) {
      this.interactActions.mgmtMenuItemClicked(m)
    }
  }

  openQuestionnaires() {
    console.log('openQuestionnaires()')
    this.interactActions.openQuestionnaires()
  }

}
