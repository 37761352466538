import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Action } from 'redux';
import * as _ from 'lodash'

import {
  IInteractState,
  InteractTab,
  InteractQuestTab,
  IQuest,
  IInteractQuestion,
  IInteractParticipant,
  IParticipantStatus
 } from '../../app.state'

import { DataService } from '../../services/data.service'
import * as util from '../../services/util.service'
import { AjaxService } from '../../services/ajax.service';
import { AppActions } from '../../app.actions'

const trace = util.traceToggle(true)


export interface IInteractAction extends Action {
  menuItem?: InteractTab
  mgmtMenuItem?: InteractQuestTab
  selectedQuestId?: number
  questionnaires?: IQuest[]
  questionnaire?: IQuest
  qid?: number
  qqid?: number
  errors?: string[]
  questions?: IInteractQuestion[]
  participants?: IInteractParticipant[]
  participantsOffset?: number
  testUserUrl?: string
  collaboration_results?: any[]
  collaborationScore?: number
  synergyScore?: number
  centralityScore?: number
  searchText?: string
  participantStatus?: IParticipantStatus
}

@Injectable()
export class InteractActions {

  static readonly INTERACT_MGMT_ERRORBOX_CLOSE = 'interact errorbox close'

  static readonly INTERACT_MENU_ITEM_CLICKED   = 'interact menu clicked'
  static readonly INTERACT_MGMT_MENU_CLICKED = 'interact mgmt menu clicked'

  static readonly INTERACT_MAP_NAV_TOGGLE = 'interact map nav toggle'

  static readonly INTERACT_MGMT_QUEST_SELECTED = 'interact mgmt quest selected'
  static readonly INTERACT_MGMT_QUEST_OPENED = 'interact mgmt quest opened'
  static readonly INTERACT_MGMT_QUEST_CLOSE  = 'interact mgmt quest close'

  static readonly INTERACT_MGMT_FETCH_QUESTIONNAIRES = 'interact mgmt fetch questionnaires'
  static readonly INTERACT_MGMT_FETCH_QUESTIONNAIRES_SUCCESS = 'interact mgmt fetch questionnaires success'
  static readonly INTERACT_MGMT_FETCH_QUESTIONNAIRES_FAIL = 'interact mgmt fetch questionnaires fail'
  static readonly INTERACT_MGMT_NEW_QUESTIONAIRE = 'interact mgmt new questionnaire'
  static readonly INTERACT_MGMT_NEW_QUESTIONAIRE_SUCCESS = 'interact mgmt new questionnaire success'
  static readonly INTERACT_MGMT_NEW_QUESTIONAIRE_FAIL = 'interact mgmt new questionnaire fail'
  static readonly INTERACT_MGMT_UPDATE_QUESTIONAIRE = 'interact mgmt update questionnaire'
  static readonly INTERACT_MGMT_UPDATE_QUESTIONAIRE_SUCCESS = 'interact mgmt update questionnaire success'
  static readonly INTERACT_MGMT_UPDATE_QUESTIONAIRE_FAIL = 'interact mgmt update questionnaire fail'
  static readonly INTERACT_MGMT_DELETE_QUESTIONAIRE = 'interact mgmt delete questionnaire'
  static readonly INTERACT_MGMT_DELETE_QUESTIONAIRE_SUCCESS = 'interact mgmt delete questionnaire success'
  static readonly INTERACT_MGMT_DELETE_QUESTIONAIRE_FAIL = 'interact mgmt delete questionnaire fail'

  static readonly INTERACT_MGMT_FETCH_QUESTIONS = 'interact mgmt fetch questions'
  static readonly INTERACT_MGMT_FETCH_QUESTIONS_SUCCESS = 'interact mgmt fetch questions success'
  static readonly INTERACT_MGMT_UPDATE_QUESTION = 'interact mgmt update question'
  static readonly INTERACT_MGMT_UPDATE_QUESTION_SUCCESS = 'interact mgmt update question success'
  static readonly INTERACT_MGMT_UPDATE_QUESTION_FAIL    = 'interact mgmt update question fail'
  static readonly INTERACT_MGMT_NEW_QUESTION = 'interact mgmt new question'
  static readonly INTERACT_MGMT_NEW_QUESTION_SUCCESS = 'interact mgmt new question success'
  static readonly INTERACT_MGMT_NEW_QUESTION_FAIL    = 'interact mgmt new question fail'
  static readonly INTERACT_MGMT_DELETE_QUESTION = 'interact mgmt delete question'
  static readonly INTERACT_MGMT_DELETE_QUESTION_SUCCESS = 'interact mgmt delete question success'
  static readonly INTERACT_MGMT_DELETE_QUESTION_FAIL    = 'interact mgmt delete question fail'
  static readonly INTERACT_MGMT_REORDER_QUESTIONS = 'interact mgmt reorder questions'
  static readonly INTERACT_MGMT_REORDER_QUESTIONS_SUCCESS = 'interact mgmt reorder questions success'
  static readonly INTERACT_MGMT_REORDER_QUESTIONS_FAIL    = 'interact mgmt reorder questions fail'
  static readonly INTERACT_MGMT_SET_ACTIVE_QUESTION = 'interact mgmt set active question'

  static readonly INTERACT_MGMT_FETCH_PARTICIPANTS = 'interact mgmt fetch participants'
  static readonly INTERACT_MGMT_FETCH_PARTICIPANTS_SUCCESS = 'interact mgmt fetch participants success'
  static readonly INTERACT_MGMT_FETCH_PARTICIPANTS_FAIL = 'interact mgmt fetch participants fail'

  static readonly INTERACT_MGMT_CLEAR_PARTICIPANTS = 'interact mgmt clear participants'
  static readonly INTERACT_MGMT_PARTICIPANTS_SET_SEARCH_TEXT = 'interact mgmt participants set search text'

  static readonly INTERACT_MGMT_UPDATE_PARTICIPANT = 'interact mgmt update participant'
  static readonly INTERACT_MGMT_UPDATE_PARTICIPANT_SUCCESS = 'interact mgmt update participant success'
  static readonly INTERACT_MGMT_UPDATE_PARTICIPANT_FAIL    = 'interact mgmt update participant fail'
  static readonly INTERACT_MGMT_NEW_PARTICIPANT = 'interact mgmt new participant'
  static readonly INTERACT_MGMT_NEW_PARTICIPANT_SUCCESS = 'interact mgmt new participant success'
  static readonly INTERACT_MGMT_NEW_PARTICIPANT_FAIL    = 'interact mgmt new participant fail'
  static readonly INTERACT_MGMT_DELETE_PARTICIPANT = 'interact mgmt delete participant'
  static readonly INTERACT_MGMT_DELETE_PARTICIPANT_SUCCESS = 'interact mgmt delete participant success'
  static readonly INTERACT_MGMT_DELETE_PARTICIPANT_FAIL    = 'interact mgmt delete participant fail'
  static readonly INTERACT_MGMT_RESEND_PARTICIPANT = 'interact mgmt resend participant'
  static readonly INTERACT_MGMT_RESEND_PARTICIPANT_SUCCESS = 'interact mgmt resend participant success'
  static readonly INTERACT_MGMT_RESEND_PARTICIPANT_FAIL    = 'interact mgmt resend participant fail'
  static readonly INTERACT_MGMT_RESET_PARTICIPANT = 'interact mgmt reset participant'
  static readonly INTERACT_MGMT_RESET_PARTICIPANT_SUCCESS = 'interact mgmt reset participant success'
  static readonly INTERACT_MGMT_RESET_PARTICIPANT_FAIL    = 'interact mgmt reset participant fail'
  static readonly INTERACT_MGMT_GET_PARTICIPANT_STATUS = 'interact mgmt get participant status'
  static readonly INTERACT_MGMT_GET_PARTICIPANT_STATUS_SUCCESS = 'interact mgmt get participant status success'
  static readonly INTERACT_MGMT_GET_PARTICIPANT_STATUS_FAIL    = 'interact mgmt get participant status fail'
  static readonly INTERACT_MGMT_PARTICIPANT_STATUS_DIALOG_CLOSE    = 'interact mgmt participant status dialog close'


  static readonly INTERACT_MGMT_UPLOAD_PARTICIPANTS = 'interact mgmt upload participants'
  static readonly INTERACT_MGMT_UPLOAD_PARTICIPANTS_SUCCESS = 'interact mgmt upload participants success'
  static readonly INTERACT_MGMT_UPLOAD_PARTICIPANTS_FAIL = 'interact mgmt upload participants fail'
  static readonly INTERACT_MGMT_UPLOAD_PHOTO = 'interact mgmt upload photo'
  static readonly INTERACT_MGMT_UPLOAD_PHOTO_SUCCESS = 'interact mgmt upload photo success'
  static readonly INTERACT_MGMT_UPLOAD_PHOTO_FAIL = 'interact mgmt upload photo fail'

  static readonly INTERACT_MGMT_RUN_QUESITONNAIRE = 'interact mgmt run questionnaire'
  static readonly INTERACT_MGMT_RUN_QUESITONNAIRE_SUCCESS = 'interact mgmt upload run questionnaire success'
  static readonly INTERACT_MGMT_RUN_QUESITONNAIRE_FAIL = 'interact mgmt run questionnaire fail'

  static readonly INTERACT_MGMT_CLOSE_QUESITONNAIRE = 'interact mgmt close questionnaire'
  static readonly INTERACT_MGMT_CLOSE_QUESITONNAIRE_SUCCESS = 'interact mgmt close questionnaire success'
  static readonly INTERACT_MGMT_CLOSE_QUESITONNAIRE_FAIL = 'interact mgmt close questionnaire fail'

  static readonly INTERACT_MGMT_UPDATE_TEST_PARTICIPANT = 'interact mgmt update test participant'
  static readonly INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_SUCCESS = 'interact mgmt update test participant success'
  static readonly INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_FAIL    = 'interact mgmt update test participant fail'

  static readonly INTERACT_COLL_FETCH_QUEST_DATA = 'interact coll fetch quest data'
  static readonly INTERACT_COLL_FETCH_QUEST_DATA_SUCCESS = 'interact coll fetch quest data success'
  static readonly INTERACT_COLL_FETCH_QUEST_DATA_FAIL = 'interact coll fetch quest data fail'

  static readonly INTERACT_COLL_FETCH_QUEST_STATS = 'interact coll fetch quest stats'
  static readonly INTERACT_COLL_FETCH_QUEST_STATS_SUCCESS = 'interact coll fetch quest stats success'
  static readonly INTERACT_COLL_FETCH_QUEST_STATS_FAIL = 'interact coll fetch quest stats fail'

  static readonly INTERACT_MGMT_COPY_QUESITONNAIRE = 'interact mgmt copy questionnaire'
  static readonly INTERACT_MGMT_COPY_QUESITONNAIRE_SUCCESS = 'interact mgmt copy questionnaire success'
  static readonly INTERACT_MGMT_COPY_QUESITONNAIRE_FAIL = 'interact mgmt copy questionnaire fail'

  static readonly INTERACT_MGMT_FOLLOWUP_QUESITONNAIRE = 'interact mgmt followup questionnaire'
  static readonly INTERACT_MGMT_FOLLOWUP_QUESITONNAIRE_SUCCESS = 'interact mgmt followup questionnaire success'
  static readonly INTERACT_MGMT_FOLLOWUP_QUESITONNAIRE_FAIL = 'interact mgmt followup questionnaire fail'

  static readonly INTERACT_MGMT_SIMULATE_QUESTIONNAIRE_REPLIES = 'interact mgmt simulate questionnaire replies'

  constructor(private ngRedux: NgRedux<IInteractState>,
              private ds: DataService,
              private as: AjaxService,
              private appActions: AppActions) {}

  errrBoxClose = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_ERRORBOX_CLOSE})
  }
/******************* Questions ******************************/

  /**
   * Get participant status
   */
  fetchParticipantStatus = (qpid: number) => {
    trace('InteractActions - In fetchParticipantStatus(), qpid: ', qpid)
    if (qpid === undefined) {return}
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_GET_PARTICIPANT_STATUS})
    const params = {qpid: qpid}

    this.ds.getParticipantStatus(
      params,
      this.fetchParticipantStatusSuccess
    )
  }
  fetchParticipantStatusSuccess = (res) => {
    trace('InteractActions - In fetchParticipantStatusSuccess() - fetch success: res = ', res)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_GET_PARTICIPANT_STATUS_SUCCESS,
      participantStatus: res.data
    })
  }

  closeParticipantsStatusDialog = () => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_PARTICIPANT_STATUS_DIALOG_CLOSE
    })
  }

  /**
   * Fetch participants
   */
  fetchParticipants = (qid: number, page: number = 0, sText: string = null) => {
    trace('InteractActions - In fetchParticipants(), qid: ', qid, ', page: ', page)
    if (qid === undefined) {return}
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_FETCH_PARTICIPANTS,
                          participantsOffset: page})

    const params = {qid: qid, page: page}
    let searchText = sText
    if (_.isNil(searchText)) {
      searchText = (<any>this.ngRedux.getState()).interact.participantsSearchText
    }

    if ( !_.isNil(searchText) )  {
      (<any>params).searchText = searchText
    }

    this.ds.getParticipants(
      params,
      this.fetchParticipantsSuccess
    )
  }
  fetchParticipantsSuccess = (res) => {
    trace('InteractActions - In fetchParticipants() - fetch success: res = ', res)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_PARTICIPANTS_SUCCESS,
      participants: res.data
    })
  }

  /**
   * Filter participants
   */
  filterParticipants = (qid: number, page: number = 0, searchText: string = null) => {
    trace('InteractActions - In filterParticipants(), qid: ', qid, ', page: ', page)
    if (qid === undefined) {return}
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLEAR_PARTICIPANTS})
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_PARTICIPANTS_SET_SEARCH_TEXT, searchText: searchText})
    this.fetchParticipants(qid, page, searchText)
  }

  /**
   * Participant update
   */
  updateParticipant = (participant: IInteractParticipant) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT, participant: participant})
    this.as.post('/interact_backoffice/participants_update',
        {participant: participant},
        this.ds.handlePostResult(this.updateParticipantSuccess,
                                 this.updateParticipantFail),
        this.updateParticipantFail
    )
  }
  updateParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
    this.appActions.spinnerOff()
    alert('Participant was updated')
  }
  updateParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to update participant')
  }

  /**
   * New participant
   */
  newParticipant = (participant: IInteractParticipant) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_PARTICIPANT, participant: participant})
    this.as.post('/interact_backoffice/participants_create',
        {participant: participant},
        this.ds.handlePostResult(this.newParticipantSuccess,
                                 this.newParticipantFail),
        this.newParticipantFail
    )
  }
  newParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_NEW_PARTICIPANT_SUCCESS,
      questionnaire: data.questionnaire,
      participants: data.participants
    })
    this.appActions.spinnerOff()
    alert('Participant was created')
  }
  newParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to create participant')
  }

  /**
   * Delete participant
   */
  deleteParticipant = (pid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT})
    this.as.post('/interact_backoffice/participants_delete',
        {qpid: pid},
        this.ds.handlePostResult(this.deleteParticipantSuccess,
                                 this.deleteParticipantFail),
        this.deleteParticipantFail
    )
  }
  deleteParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
    this.appActions.spinnerOff()
    alert('Participant deleted')
  }
  deleteParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to delete participant')
  }

  /**
   * Reset participant
   */
  resetParticipant = (pid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESET_PARTICIPANT})
    this.as.post('/interact_backoffice/participant_reset',
        {qpid: pid},
        this.ds.handlePostResult(this.resetParticipantSuccess,
                                 this.resetParticipantFail),
        this.resetParticipantFail
    )
  }
  resetParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_RESET_PARTICIPANT_SUCCESS,
      participants: data.participants
    })
    this.appActions.spinnerOff()
    alert('Questionnaire was reset')
  }
  resetParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESET_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to reset questionnaire')
  }

  /**
   * Resend participant
   */
  resendParticipant = (pid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESEND_PARTICIPANT})
    this.as.post('/interact_backoffice/participant_resend',
        {qpid: pid},
        this.ds.handlePostResult(this.resendParticipantSuccess,
                                 this.resendParticipantFail),
        this.resendParticipantFail
    )
  }
  resendParticipantSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_RESEND_PARTICIPANT_SUCCESS,
      participants: data.participants
    })
    this.appActions.spinnerOff()
    alert('Questionnaire was re-sent')
  }
  resendParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RESEND_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to re-send questionnaire')
  }

  /******************* Questions ******************************/
  /**
   * Fetch questions
   */
  fetchQuestions = (qid) => {
    trace('InteractActions - In fetchQuestions()')
    if (qid === undefined) {return}

    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONS})
    this.ds.getQuestions(
      {qid: qid},
      this.fetchQuestionsSuccess
    )
  }
  fetchQuestionsSuccess = (res) => {
    trace('InteractActions - In fetchQuestions() - fetch success: res = ', res)
    const questions = res.data.questions
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONS_SUCCESS,
      questions: questions
    })
  }

  /**
   * Question update
   */
  updateQuestion = (question: IInteractQuestion) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTION, question: question})
    this.as.post('/interact_backoffice/questions_update',
        {question: question},
        this.ds.handlePostResult(this.updateQuestionSuccess,
                                 this.updateQuestionFail),
        this.updateQuestionFail
    )
  }
  updateQuestionSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_QUESTION_SUCCESS,
      questions: data.questions,
      questionnaire: data.questionnaire
    })
  }
  updateQuestionFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTION_FAIL})
    console.error(err)
  }

  /**
   * New question
   */
  newQuestion = (question: IInteractQuestion, qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTION, question: question})
    this.as.post('/interact_backoffice/questions_create',
        {question: question, qid: qid},
        this.ds.handlePostResult(this.newQuestionSuccess,
                                 this.newQuestionFail),
        this.newQuestionFail
    )
  }
  newQuestionSuccess = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTION_SUCCESS})
  }
  newQuestionFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTION_FAIL})
    console.error(err)
  }

  /**
   * Delete question
   */
  deleteQuestion = (qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTION, qid: qid})
    this.as.post('/interact_backoffice/questions_delete',
        {qid: qid},
        this.ds.handlePostResult(this.deleteQuestionSuccess,
                                 this.deleteQuestionFail),
        this.deleteQuestionFail
    )
  }
  deleteQuestionSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_DELETE_QUESTION_SUCCESS,
      questions: data.questions
    })
  }
  deleteQuestionFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTION_FAIL})
    console.error(err)
  }

  /**
   * Reorder questions
   */
  reorderQuestions = (questions: any[]) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REORDER_QUESTIONS})
    this.as.post('/interact_backoffice/questions_reorder',
        {questions: questions},
        this.ds.handlePostResult(this.reorderQuestionsSuccess,
                                 this.reorderQuestionsFail),
        this.reorderQuestionsFail
    )
  }
  reorderQuestionsSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_REORDER_QUESTIONS_SUCCESS})
  }
  reorderQuestionsFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_REORDER_QUESTIONS_FAIL})
    console.error(err)
  }

  /**
   * Active question
   */
  setActiveQuestion = (qqid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SET_ACTIVE_QUESTION, qqid: qqid})
  }


  /******************* Questionnaires ******************************/
  /**
   * Fetch questionnaires
   */
  fetchQuestionnaires = () => {
    trace('InteractActions - In fetchQuestionnaires()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES})
    this.ds.getQuestionnaires({}, (res) => {
      trace('InteractActions - In fetchQuestionnaires() - fetch success: res = ', res)
      this.fetchQuestionnairesSuccess(res)
    })
  }
  fetchQuestionnairesSuccess = (res) => {
    const quests = res.data
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES_SUCCESS,
      questionnaires: quests
    })
  }
  fetchQuestionnairesFail = (err) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES_FAIL,
      errors: err
    })
  }

  /**
   * New questionnair
   */
  createNewQuestionnaire = () => {
    trace('InteractActions - In createNewQuestionnaire()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE})
    this.as.get(
      '/interact_backoffice/questionnaire_create',
      {},
      this.ds.handlePostResult(this.createNewQuestionnaireSuccess,
                               this.createNewQuestionnaireFail),
      this.createNewQuestionnaireFail
    )
  }
  createNewQuestionnaireSuccess = (res) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE_SUCCESS,
      questionnaires: res
    })
  }
  createNewQuestionnaireFail = (err) => {
    console.error(err)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE_FAIL,
      errors: err
    })
  }

  /**
   * Delete questionnair
   */
  deleteQuestionnaire = (qid) => {
    trace('InteractActions - In deleteQuestionnaire()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE})
    this.as.post(
      '/interact_backoffice/questionnaire_delete',
      {qid: qid},
      this.ds.handlePostResult(this.deleteQuestionnaireSuccess,
                               this.deleteQuestionnaireFail),
      this.deleteQuestionnaireFail
    )
  }
  deleteQuestionnaireSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE_SUCCESS,
      questionnaires: data
    })
  }
  deleteQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE_FAIL})
    console.error(err)
  }

  /**
   * Questionnaire update
   */
  updateQuestionnaire = (quest: IQuest) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE, questionnaire: quest})
    this.as.post('/interact_backoffice/questionnaire_update',
        {questionnaire: quest},
        this.ds.handlePostResult(this.updateQuestionnaireSuccess,
                                 this.updateQuestionnaireFail),
        this.updateQuestionnaireFail
    )
  }
  updateQuestionnaireSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE_SUCCESS,
      questionnaires: data.quests,
      questionnaire: data.activeQuest
    })
  }
  updateQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE_FAIL, errors: err})
    console.error(err)
  }

  questSelected = (questId: number) => {
    trace('InteractAtions - questSelected() - questId: ', questId)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_QUEST_SELECTED,
      selectedQuestId: questId
    })
  }

  questOpened = (questId: number) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_QUEST_OPENED,
      selectedQuestId: questId
    })
  }

  openQuestionnaires = () => {
    console.log('actions = openQuestionnaires(')
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_QUEST_CLOSE
    })
  }

  /**
   * Test participant update
   */
  updateTestParticipant = (quest: IQuest) => {
    this.appActions.spinnerOn()
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT, questionnaire: quest})
    this.as.post('/interact_backoffice/update_test_participant',
        {questionnaire: quest},
        this.ds.handlePostResult(this.updateTestParticipantSuccess,
                                 this.updateTestParticipantFail),
        this.updateTestParticipantFail
    )
  }
  updateTestParticipantSuccess = (data) => {
    console.log('data: ', data)
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_SUCCESS,
      questionnaire: data.questionnaire,
      testUserUrl: data.test_user_url
    })
    this.appActions.spinnerOff()
    alert('Test questionaire sent')
  }
  updateTestParticipantFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to send questionaire sent')
  }



  /******************* Interact actions ***************************/
  uploadParticipants = (formData: FormData) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS})
    this.as.post(
      '/interact_backoffice/actions_upload_participants',
      formData,
      this.ds.handlePostResult(
          this.uploadParticipantsSuccess,
          this.uploadParticipantsFail),
      this.uploadParticipantsFail)
  }
  uploadParticipantsSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS_SUCCESS,
      participants: data.participants,
      questionnaire: data.questionnaire
    })
  }
  uploadParticipantsFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS_FAIL})
    console.error(err)
  }


  uploadParticipantPhotos = (formData: FormData) => {
    console.log('InteractActions - In uploadParticipantPhotos()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO})
    this.as.post(
      '/interact_backoffice/actions_img_upload',
      formData,
      this.ds.handlePostResult(
          this.uploadParticipantPhotosSuccess,
          this.uploadParticipantPhotosFail),
      this.uploadParticipantPhotosFail)
  }
  uploadParticipantPhotosSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_SUCCESS,
      participants: data.participants
    })
  }
  uploadParticipantPhotosFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_FAIL, errors: err})
    console.error(err)
  }
  processParticipantPhotosFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_FAIL, errors: err})
    console.error(err)
  }

  /******************* Run questionnaire ******************************/
  runQuestionnaire = (qid: number) => {
    this.appActions.spinnerOn()
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE})
    this.as.post(
      '/interact_backoffice/quesitonnaire_run',
      {qid: qid},
      this.ds.handlePostResult(
          this.runQuestionnaireSuccess,
          this.runQuestionnaireFail),
      this.runQuestionnaireFail)
  }
  runQuestionnaireSuccess = (data) => {
    this.appActions.spinnerOff()
    alert('Questionnaire was sent successfully')
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE_SUCCESS,
      questionnaire: data.questionnaire
    })
  }
  runQuestionnaireFail = (err) => {
    this.appActions.spinnerOff()
    alert(`Error while sending questionnaire: ${err}`)
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE_FAIL})
    console.error(err)
  }

  /******************* Close questionnaire ******************************/

  closeQuestionnaire = (qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE})
    this.appActions.spinnerOn()
    this.as.post(
      '/interact_backoffice/quesitonnaire_close',
      {qid: qid},
      this.ds.handlePostResult(
          this.closeQuestionnaireSuccess,
          this.closeQuestionnaireFail),
      this.closeQuestionnaireFail)
  }
  closeQuestionnaireSuccess = (data) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE_SUCCESS,
      questionnaire: data.questionnaire
    })
    this.appActions.spinnerOff()
    alert('Questionnaire successfully closed')
  }
  closeQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE_FAIL})
    console.error(err)
    this.appActions.spinnerOff()
    alert('Failed to close questionnaire')
  }

  /******************* Menu handling ******************************/
  menuItemClicked = (item: InteractTab ) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MENU_ITEM_CLICKED,
      menuItem: item
    })
  }

  mgmtMenuItemClicked = (item: InteractQuestTab ) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_MENU_CLICKED,
      mgmtMenuItem: item
    })
  }

  /******************** Top button actions **************************/
  downloadSample = () => {
    console.log('calling download Sample')
    this.as.download('/interact_backoffice/actions_download_sample',
        {},
        () => { console.log('Download sample success') }
    )
  }

  downloadEmployees = () => {
    this.as.download('/interact_backoffice/participants_get_emps',
        {},
        () => { console.log('Download sample success') }
    )
  }

  downloadParticipantsStatus = (qid: number) => {
    this.as.download('/interact_backoffice/actions_participants_status',
      {qid: qid},
      () => { console.log('Download status success') }
    )
  }

  /******************** Questionnaire data **************************/
  fetchQuestionnaireData = (qqid: number, gid: number) => {
    trace('InteractActions - In fetchQuestionnaireData()')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_COLL_FETCH_QUEST_DATA})
    this.ds.getQuestionnaireData({qqid: qqid, gid: gid}, (res) => {
      this.fetchQuestionnaireDataSuccess(res)
    })
  }
  fetchQuestionnaireDataSuccess = (res) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_COLL_FETCH_QUEST_DATA_SUCCESS,
      collaboration_results: res.indeg,
      collaborationScore: res.collaboration,
      synergyScore: res.synergy,
      centralityScore: res.centrality
    })
  }
  fetchQuestionnaireDataFail = (err) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_COLL_FETCH_QUEST_DATA_FAIL,
      errors: err
    })
  }

  /******************** Reports **************************/
  downloadQuestionnaireReport = (qid: number) => {
    this.appActions.spinnerOn()
    this.as.download('/interact_backoffice/reports_network',
        {qid: qid},
        () => {
          this.appActions.spinnerOff()
          console.log('Download questionnaire report success')
        }
    )
  }

  downloadBidirectionalReport = (qid: number) => {
    this.appActions.spinnerOn()
    this.as.download('/interact_backoffice/reports_bidirectional_network',
        {qid: qid},
        () => {
          this.appActions.spinnerOff()
          console.log('Download bidirectional network report success')
        }
    )
  }

  scoresReport = (qid: number) => {
    this.appActions.spinnerOn()
    this.as.download('/interact_backoffice/reports_measures',
        {qid: qid},
        () => {
          this.appActions.spinnerOff()
          console.log('Download scores report success')
        }
    )
  }

/************************* Copy questionnaire *************************************/
  copyQuestionnaire = (qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE})
    this.as.post(
      '/interact_backoffice/quesitonnaire_copy',
      {qid: qid},
      this.ds.handlePostResult(
          this.copyQuestionnaireSuccess,
          this.copyQuestionnaireFail),
      this.copyQuestionnaireFail)
  }
  copyQuestionnaireSuccess = (res) => {
    this.ngRedux.dispatch({
      type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE_SUCCESS,
      questionnaires: res
    })
  }
  copyQuestionnaireFail = (err) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE_FAIL})
    console.error(err)
  }

  /************************* Rerun questionnaire *************************************/
  rerunQuestionnaire = (qid: number) => {
    alert('Running questionaire ...')
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE})
    this.as.post(
      '/interact_backoffice/quesitonnaire_copy',
      {qid: qid, rerun: true},
      this.ds.handlePostResult(
          this.copyQuestionnaireSuccess,
          this.copyQuestionnaireFail),
      this.copyQuestionnaireFail)
  }

  /************************* Simulate questionnaire replies ****************************/
  simulateQuestionnaireReplies = (qid: number) => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MGMT_SIMULATE_QUESTIONNAIRE_REPLIES})
    this.as.post(
      '/interact_backoffice/simulate_replies',
      {qid: qid},
      this.ds.handlePostResult(
          () => {console.log('simulateQuestionnaireReplies success')},
          () => {console.log('simulateQuestionnaireReplies success')}),
      () => {console.log('simulateQuestionnaireReplies success')})
  }

  /**************************************************************/
  mapNavToggle = () => {
    this.ngRedux.dispatch({type: InteractActions.INTERACT_MAP_NAV_TOGGLE})
  }
}
