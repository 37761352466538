import * as _ from 'lodash'

import { INavAction, NavActions } from './nav.actions';
import {
  IGroupsState,
  IGroup,
  INITIAL_STATE_GROUPS,
  IGroupsHashMap } from '../../app.state'
import * as util from '../../services/util.service'

/**
 * We're using this clone function instead of Object.assign because Object.assign
 * doesn't handle object keys which are not string or symbole, and in our case
 * the groups memeber is a hash table with number keys.
 */
const cloneGroupState = (state: IGroupsState): IGroupsState => {
  const newGroups = {}
  _.forEach(state.groups, (v: IGroup, k: number) => {
    newGroups[k] = v
  })
  state.groups = newGroups
  return Object.assign({}, state)
}

export function navReducer(lastState: IGroupsState, action: INavAction): IGroupsState {
  if (lastState === undefined) { return INITIAL_STATE_GROUPS }

  switch (action.type) {
    case NavActions.EXPAND_GROUP: {
      const cloned = showChildGroups( cloneGroupState(lastState), action.id)
      return expandGroup(cloned, action.id)
    }
    case NavActions.COLLAPSE_GROUP: return collapseGroup(cloneGroupState(lastState), action.id)
    case NavActions.SELECT_GROUP: return selectGroup(cloneGroupState(lastState), action.id)
    case NavActions.DESELECT_GROUP: return deselectGroup(cloneGroupState(lastState), action.id)
    case NavActions.GROUP_SEARCH_CHANGED: return groupSearChanged(cloneGroupState(lastState), action.searchText)
    case NavActions.FETCH_GROUPS: return lastState
    case NavActions.FETCH_GROUPS_SUCCESS: {
      const cloned = cloneGroupState(lastState)
      cloned.groups = processGroupsResultFromServer(action.groups)
      cloned.numberOfSelected = updateNumberOfSelected(action.groups)
      return cloned
    }
    case NavActions.FETCH_GROUPS_ERROR: return lastState
    default:
      return lastState
  }
}

/////////////////////////////// Private functions ////////////////////////////////
const depthByLines = ['', '_', '__', '___', '____', '_____', '______', '________']

export const updateNumberOfSelected = (groups: IGroupsHashMap): number => {
  return _.reduce(groups, (res, g: IGroup) => (g.isSelected) ? res += g.size : res , 0)
}

const findRootGroup = (groups: any): IGroup => {
  return _.find(groups, (v: IGroup) => (v.parentId === null || v.parentId === -1) )
}

// Important - this data structure transformation does not conserve group id (gid)
//   indicies, rather it references client groups in terms of client ids. gids are still
//   saved so we can reference groups in the server.
export const processGroupsResultFromServer = (groups: any): IGroupsHashMap => {
  let sortedGroups: IGroupsHashMap = {}
  const rootgroup: IGroup = findRootGroup(groups)
  sortedGroups = hierarchicalGroupsSort(groups, rootgroup.gid, sortedGroups)

  const keys: number[] = []
  _.forEach (sortedGroups, (g: IGroup, k) => {
    keys[g.gid] = Number.parseInt(k, 0)
  })

  const res: IGroupsHashMap = {}
  _.forEach(sortedGroups, (g, k) => {
    g.parentId = g.parentId === null || g.parentId === -1 ? null : keys[g.parentId]
    g.depth = depthByLines[g.depth]
    g.isSelected = true
    g.isExpanded = g.parentId === null || g.parentId === -1 ? true : false
    g.isHidden   = (g.parentId === keys[rootgroup.gid] || g.parentId === null || g.parentId === -1) ? false : true
    g.childrenIds = _.map(g.childrenIds, (v: number) => keys[v])
    res[k] = g
  })

  return res
}

const hierarchicalGroupsSort = (groups: any, gid: number, sortedGroups: IGroupsHashMap): IGroupsHashMap => {
  const group: IGroup = groups[gid]
  if (group === undefined) {
    return sortedGroups
  }

  const keyId: number = Object.keys(sortedGroups).length
  sortedGroups[keyId] = group
  if (group.childrenIds.length !== 0) {
    _.forEach(group.childrenIds, (i: number) => {
      sortedGroups = hierarchicalGroupsSort(groups, i, sortedGroups)
    })
  }
  return sortedGroups
}

function expandGroup(state: IGroupsState, id: number): IGroupsState {
  if (id === null) {return state }
  if ( hasNoChildren(state, id) ) {return state}
  state.groups[id].isExpanded = true
  state.groups[id].isHidden = false
  state.groups[id].childrenIds.forEach((childId) => {
    state.groups[childId].isHidden = false
  })
  const parent = state.groups[state.groups[id].parentId]
  if (parent === undefined || parent.isHidden === true) {
    return state
  } else {
    return expandGroup(state, state.groups[id].parentId)
  }
}

function showChildGroups(state, id): IGroupsState {
  state.groups[id].childrenIds.forEach((childId) => {
    state.groups[childId].isHidden = false
  })
  return state
}

function collapseGroup(state: IGroupsState, id: number): IGroupsState {
  const childGroups: number[] = state.groups[id].childrenIds
  if (childGroups.length === 0) { return state }
  const newState: IGroupsState = childGroups.reduce( (st, e) => collapseGroup(st, e), state )
  hideChildGroups(newState, id)
  newState.groups[id].isExpanded = false
  return newState
}

function hideChildGroups(state, id): IGroupsState {
  state.groups[id].childrenIds.forEach((childId) => {
    state.groups[childId].isHidden = true
  })
  return state
}

function hasNoChildren(state: IGroupsState, id: number): boolean {
  return state.groups[id].childrenIds.length === 0
}

const groupSearChanged = (state: IGroupsState, text: string): IGroupsState => {
  if (text.length === 0) {
    state.groups = resetGroupsRepresentation(state.groups)
    state.numberOfSelected = updateNumberOfSelected(state.groups)
    return state
  }

  const groups: IGroup[] = _.map(state.groups, (g: IGroup) => {
    const isMatching: boolean = g.name.toLowerCase().indexOf(text) > -1
    g.isHidden = isMatching ? false : true
    g.isSelected = isMatching ? true : false
    return g
  })
  return {numberOfSelected: updateNumberOfSelected(groups), groups: groups}
}

const resetGroupsRepresentation = (groups: IGroup[]): IGroup[] => {
  const rootGroup = _.find(groups, (g: IGroup) => g.parentId === null )
  const newGroups = _.map(groups, (g: IGroup) => {
    g.isSelected = true
    if (g.gid === rootGroup.gid) {
      g.isExpanded = true
      g.isHidden = false
      return g
    }

    if (g.parentId === 0) {
      g.isExpanded = false
      g.isHidden = false
      return g
    }

    if (g.parentId !== 0) {
      g.isExpanded = false
      g.isHidden = true
      return g
    }
  })
  return newGroups
}

function selectGroup(state: IGroupsState, id: number): IGroupsState {
  const rootGroup = findRootGroup(state.groups)
  const numEmpsInAll = rootGroup.accumulatedSize

  // const isRootGroup = state.groups[id].parentId === null
  if (state.numberOfSelected === numEmpsInAll) { state.numberOfSelected = 0 }

  // Check leaf nodes
  const childGroups: number[] = state.groups[id].childrenIds
  if (childGroups.length === 0 && state.groups[id].isSelected === false) {

    state.groups[id].isSelected = true
    state.numberOfSelected += state.groups[id].size
    return state
  } else if (childGroups.length === 0 && state.groups[id].isSelected === true) {
    return state
  }

  // Not a leaf node and not selected, so we traverse below
  const newState: IGroupsState = childGroups.reduce( (st, e) => selectGroup(st, e), state )
  if (!newState.groups[id].isSelected) {
    newState.groups[id].isSelected = true
    newState.numberOfSelected += newState.groups[id].size
  }
  return newState
}

function deselectGroup(state: IGroupsState, id: number): IGroupsState {
  // Check leaf nodes
  const childGroups: number[] = state.groups[id].childrenIds
  if (childGroups.length === 0 && state.groups[id].isSelected === true) {
    state.groups[id].isSelected = false
    state.numberOfSelected -= state.groups[id].size
    return state
  } else if (childGroups.length === 0 && state.groups[id].isSelected === false) {
    return state
  }

  // Not a leaf node and selected, so we traverse below
  const newState: IGroupsState = childGroups.reduce( (st, e) => deselectGroup(st, e), state )
  if (newState.groups[id].isSelected) {
    newState.groups[id].isSelected = false
    newState.numberOfSelected -= newState.groups[id].size
  }

  if (newState.numberOfSelected === 0) { newState.numberOfSelected = findRootGroup(state.groups).accumulatedSize}
  return newState
}
