import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Action } from 'redux';
import * as _ from 'lodash'

import * as util from '../../services/util.service'
import {
  IMapState,
  IFilter
} from '../../app.state'
import { CombineService } from './combine.service'
import { DataService } from '../../services/data.service'


const trace = util.traceToggle(false)

export interface IMapAction extends Action {
  mapId: string
  nid?: number,
  initialMap?: IMapState,
  cs?: CombineService,
  filters?: IFilter[],
  colorby?: string,
  textToSearch?: string,
  mapData?: IMapState,
  edgesFilter?: number
}

@Injectable()
export class MapActions {

  static readonly MAP_INITIALIZE = 'map initialize'
  static readonly MAP_RESET = 'map reset'
  static readonly MAP_REDRAW_DONE = 'map redraw is done'
  static readonly MAP_RECOLOR = 'map recolor'
  static readonly MAP_HANDLE_DBCLICK = 'map handle dblclick'
  static readonly MAP_COLORBY_SELECTED = 'map colorby selected'
  static readonly MAP_UPDATE_FILTERS = 'map update filters'
  static readonly MAP_SEARCH_TEXT = 'map search text'
  static readonly MAP_GROUP_ALL = 'map group all'
  static readonly MAP_CLICK_TO_ISOLATE = 'map click to isolate'

  static readonly MAP_HIDE_SHOW_NAMES = 'map hide show names'
  static readonly MAP_TOGGLE_UNI_COLORS = 'map toggle uni-colors'

  static readonly MAP_CHANGE_TO_EDGES_FILTER = 'map change to edges filter'
  static readonly MAP_CHANGE_FROM_EDGES_FILTER = 'map change from edges filter'
  static readonly MAP_SHOW_CORE_NETWORK = 'map show core network'

  static readonly MAP_FETCH = 'map fetch'
  static readonly MAP_FETCH_FAIL = 'map fetch fail'

  cs: CombineService

  constructor(private ngRedux: NgRedux<IMapState>,
              private ds: DataService) {
          this.cs = new CombineService()
  }

  dispatch = (mapId: string, p) => {
    const params: Action =
      Object.assign({}, {
        type: p.type,
        cs: this.cs,
        mapId: mapId
      }, p)
    this.ngRedux.dispatch(params)
  }
  /******************* Grouping actions ******************************/
  mapInitialize = (mid: string, map: IMapState) => {
    trace('MapActions - In mapInitianlize()')
    this.dispatch(mid, {type: MapActions.MAP_INITIALIZE , initialMap: map})
  }

  handleDbClick = (mid: string, nid: number|string) => {
    trace('MapActions - In groupOnce(), qid: ', nid)
    this.dispatch(mid, {type: MapActions.MAP_HANDLE_DBCLICK, nid: nid})
  }

  resetMap = (mid: string) => {
    trace('MapActions - In resetMap()')
    this.dispatch(mid, {type: MapActions.MAP_RESET})
  }

  groupAll = (mid: string) => {
    trace('MapActions - In groupAll()')
    this.dispatch(mid, {type: MapActions.MAP_GROUP_ALL})
  }

  /******************* Coloring actions ******************************/
  reColorMap = (mid: string) => {
    trace('MapActions - In resetMap()')
    this.dispatch(mid, {type: MapActions.MAP_RECOLOR})
  }

  mapRedrawIsDone = (mid: string) => {
    this.dispatch(mid, {type: MapActions.MAP_REDRAW_DONE})
  }

  colorbySelected = (mid: string, colorby: string, filters: IFilter[] ) => {
    trace('MapActions - In colorbySelected(), colorby: ', colorby)
    this.dispatch(mid, {type: MapActions.MAP_COLORBY_SELECTED, colorby: colorby, filters: filters})
  }

  updateFitlers = (mid: string) => {
    trace('MapActions - In colorbySelected(), filters: ')
    this.dispatch(mid, {type: MapActions.MAP_UPDATE_FILTERS})
  }

  searchNode = (mid: string, searchText: string) => {
    this.dispatch(mid, {type: MapActions.MAP_SEARCH_TEXT, textToSearch: searchText})
  }

  toggleHideShowNames = (mid: string, hideNames: boolean) => {
    this.dispatch(mid, {type: MapActions.MAP_HIDE_SHOW_NAMES})
  }

  toggleUniColors = (mid: string, uniColors: boolean) => {
    this.dispatch(mid, {type: MapActions.MAP_TOGGLE_UNI_COLORS})
  }

  changeEdgesFilter = (mid: string, edgesFilter: number, dir: string) => {
    if (dir === 'from') {
      this.dispatch(mid, {type: MapActions.MAP_CHANGE_FROM_EDGES_FILTER, edgesFilter: edgesFilter})
    } else {
      this.dispatch(mid, {type: MapActions.MAP_CHANGE_TO_EDGES_FILTER, edgesFilter: edgesFilter})
    }
  }

  toggleCoreNetwork = (mid: string) => {
    this.dispatch(mid, {type: MapActions.MAP_SHOW_CORE_NETWORK})
  }

  handleClickToIsolate = (mid: string, nid: number) => {
    this.dispatch(mid, {type: MapActions.MAP_CLICK_TO_ISOLATE, nid: nid})
  }

  /******************* Get the map ***********************************/
  fetchMapData = (mid: string, qqid: number, gids: number[] = []) => {
    trace('MapActions - In fetchMapData()')
    gids = _.isNil(gids) ? [] : gids
    this.dispatch(mid, {type: MapActions.MAP_FETCH})
    // this.ds.getMapData({qqid: qqid, gids: [1259, 1252, 1253]}, (res) => {
    this.ds.getMapData({qqid: qqid, gids: gids}, (res) => {
      trace('MapActions - In fetchMapData() - fetch success, qqid: ', qqid, ', gids: ', gids)
      this.fetchMapDataSuccess(mid, res)
    })
  }
  fetchMapDataSuccess = (mid: string, res) => {
    trace('fetchMapDataSuccess - res: ', res)
    this.dispatch(mid, {type: MapActions.MAP_INITIALIZE, initialMap: res})
  }

  getCombineService = () => {
    return this.cs
  }

}
