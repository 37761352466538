import * as _ from 'lodash'

import {
  IMapState,
  INITIAL_STATE_MAP,
  INITIAL_STATE_MAP_FILTER,
  IMapData,
  IMapGroup,
  NetworkType,
  IFilter
} from '../../app.state'
import * as Constants from '../../constants'
import { Maybe } from '../../types/maybe'
import {
  IMapAction,
  MapActions
} from './map.actions'
import { getRootGroupFromMapData } from '../../services/groups.service'
import { ICsMap } from './combine.service'
import * as csa from './combine.service.adapter'
import * as graphService from '../../services/graph.service'
import { MapNavComponent } from './map-nav.component'

import * as util from '../../services/util.service'

const trace = util.traceToggle(false)

/**
 * A map can be used in several places in the application, so this functionality will
 * be duplicated accross differet parts in the redux. Using this creator function allows
 * us to maintain separate states for them.
 */
export function mapReducerCreator(mapId: string) {
  return function mapReducer(lastState: IMapState, action: IMapAction): IMapState {
    if (lastState === undefined)    { return INITIAL_STATE_MAP }
    if (action.mapId !== mapId) { return lastState}

    let newState: IMapState
    let csMap: ICsMap
    if (action.type === MapActions.MAP_HANDLE_DBCLICK ||
        action.type === MapActions.MAP_RESET ||
        action.type === MapActions.MAP_GROUP_ALL ||
        action.type === MapActions.MAP_COLORBY_SELECTED) {
      csMap = csa.klToCsAll( lastState )
    }

    switch (action.type) {

      case MapActions.MAP_INITIALIZE: {
        trace('mapReducer - In MAP_INITIALIZE')

        const initialMap = action.initialMap

        initialMap.links =  formatLinks(initialMap.links, initialMap.selected_eid)

        initialMap.nodes =  formatNodes(initialMap.nodes, initialMap.selected_eid)
        const newFilters = MapNavComponent.nodesToFitlers(initialMap.nodes, lastState.filters)
        initialMap.nodes = reColorNodes(_.cloneDeep(initialMap.nodes), newFilters, lastState.colorBy)

        if (lastState.colorBy === 'Structure') {
          initialMap.groups = formatGroupsTree(initialMap.groups)
        } else {
          initialMap.groups = formatGroupsFlat(lastState.colorBy, newFilters)
        }

        csMap = csa.klToCsAll( initialMap )

        action.cs.resetData()
        csMap = action.cs.combineServiceInitialize( csMap )

        newState = Object.assign({}, csa.csToKlAll( csMap ) )

        newState.questionnaireName = action.initialMap.questionnaireName
        newState.questionTitle = action.initialMap.questionTitle

        newState.department =
          Maybe.fromValue(newState.groups)
            .flatMap( groups => Maybe.fromValue(getRootGroupFromMapData(groups)) )
            .flatMap( rootGroup => Maybe.fromValue(rootGroup.name))
            .getOrElse('NA')

        newState.colorBy = lastState.colorBy
        newState.filtersStr = 'None'
        newState.filters = newFilters
        newState.edgesFromFilter = 1
        newState.edgesToFilter = 6
        newState.showCoreNetwork = false
        newState.hideNames = false

        newState.redraw = true
        newState.type = NetworkType.boolean

        if (newState.nodes.length > 0) {
          newState.nodes = reColorNodes(newState.nodes, newState.filters, lastState.colorBy)
        }

        return newState
      }

      case MapActions.MAP_RECOLOR: {
        newState = _.cloneDeep(lastState)
        newState.nodes = reColorNodes(newState.nodes, lastState.filters, lastState.colorBy)
        newState.redraw = false
        return Object.assign({}, newState)
      }

      case MapActions.MAP_RESET: {
        csMap = action.cs.ungroupAll(csMap)
        newState = csa.csToKlAll( csMap )
        newState.redraw = true
        newState.hideNames = false
        newState.filters = INITIAL_STATE_MAP_FILTER
        newState.colorBy = 'Structure'
        newState.questionnaireName = lastState.questionnaireName
        newState.questionTitle = lastState.questionTitle
        newState.department = lastState.department
        newState.edgesFromFilter = 1
        newState.edgesToFilter = 6
        newState.showCoreNetwork = false
        return newState
      }

      case MapActions.MAP_HANDLE_DBCLICK: {
        if ( action.cs.isCombo(action.nid) ) {
          csMap = action.cs.ungroupComboOnceById(csMap, action.nid)
        } else {
          const gid = action.cs.groupIdFromNode(action.nid)
          csMap = action.cs.collapseBranchUnderGroup(csMap, gid)
        }
        newState = csa.csToKlAll( csMap )
        if (newState.hideNames) {newState.nodes = hideNodeNames(newState.nodes, lastState.clickToIsolateNodeId)}
        newState.redraw = true
        return newState
      }

      case MapActions.MAP_GROUP_ALL: {
        csMap = action.cs.collapseAll(csMap, NetworkType.boolean)
        newState = csa.csToKlAll( csMap )
        if (newState.hideNames) {newState.nodes = hideNodeNames(newState.nodes, lastState.clickToIsolateNodeId)}
        newState.redraw = true
        return newState
      }

      case MapActions.MAP_UPDATE_FILTERS: {
        if (lastState.nodes.length === 0) {return}
        const filters = MapNavComponent.nodesToFitlers(lastState.nodes, lastState.filters)
        const filtersStr = MapNavComponent.filtersToString(filters)
        return Object.assign({}, lastState, {
          filters: filters,
          redraw: lastState.redraw,
          filtersStr: filtersStr
        })
      }

      case MapActions.MAP_REDRAW_DONE: {
        return Object.assign({}, lastState, {redraw: false})
      }

      /**
       * Reaching this action means that the colorby setting was changed. We operate in two
       * modes: (1) Structure, (2) all the rest. This is why we have cases here.
       */
      case MapActions.MAP_COLORBY_SELECTED: {
        let origGroups: IMapGroup[]
        let groups: IMapGroup[]
        let nodes: IMapData[]

        newState = _.cloneDeep(lastState)
        const redraw = action.cs.isGrouped()

        nodes = lastState.nodes

        if (action.colorby === 'Structure') {
          if (lastState.origGroups !== undefined) {
            groups = lastState.origGroups
            origGroups = undefined
          } else {
            groups = lastState.groups
          }
          nodes = csa.csToKlNodes( action.cs.getAllNodes() )
          nodes = _.map(nodes, (n) => {
            n.group_id = n.orig_group_id
            n.hi = false
            return n
          })

        } else {
          origGroups = lastState.groups
          groups = createGroupsFromFilter(action.colorby, action.filters)

          if (redraw) {
            const newCsMap = action.cs.ungroupAll(csMap)
            nodes = csa.csToKlNodes( newCsMap.nodes )
          }
        }
        if (groups === null) {return lastState}
        nodes = reColorNodes(nodes, action.filters, action.colorby)

        action.cs.resetData()
        csMap = {
          nodes: csa.klToCsNodes(nodes),
          groups: csa.toCsGroups(groups),
          links: csa.klToCsLinks(newState.links),
          type: NetworkType.boolean,
          redraw: false,
          filters: INITIAL_STATE_MAP_FILTER,
          hideNames: false,
          uniColors: false
        }

        csMap = action.cs.combineServiceInitialize( csMap )
        newState = Object.assign({}, csa.csToKlAll( csMap ) )

        newState.groups            = groups
        newState.origGroups        = origGroups
        newState.nodes             = nodes
        newState.questionnaireName = lastState.questionnaireName
        newState.questionTitle     = lastState.questionTitle
        newState.department        = lastState.department
        newState.colorBy           = action.colorby
        newState.filtersStr        = 'None'
        newState.filters           = lastState.filters
        newState.type              = NetworkType.boolean
        newState.redraw            = redraw
        newState.edgesFromFilter   = lastState.edgesFromFilter
        newState.edgesToFilter     = lastState.edgesToFilter
        newState.showCoreNetwork   = lastState.showCoreNetwork

        return newState
      }

      case MapActions.MAP_SEARCH_TEXT: {
        const text = action.textToSearch.toLowerCase()
        newState = _.cloneDeep(lastState)
        newState.nodes = _.map(newState.nodes, (n: IMapData) => {
          n.ha0 = null
          return n
        })

        if (text.length === 0) { return newState }

        const searchedNode = _.find(newState.nodes, (n: IMapData) => {
          return (n.type === 'node' && n.t.toLocaleLowerCase().indexOf(text) !== -1 )
        })

        if (searchedNode !== undefined) {
          searchedNode.ha0 = {c: '#0071bc', r: 33, w: 10}
        }
        return Object.assign({}, newState)
      }

      case MapActions.MAP_FETCH: { return lastState}
      case MapActions.MAP_FETCH_FAIL: { return lastState}

      case MapActions.MAP_HIDE_SHOW_NAMES: {
        const hideNames = !lastState.hideNames
        let nodes: IMapData[] = lastState.nodes
        if (hideNames) {
          nodes = hideNodeNames(nodes, lastState.clickToIsolateNodeId)
        } else {
          nodes = csa.csToKlNodes( action.cs.getAllNodes(true) )
        }

        return Object.assign({}, lastState, {
            hideNames: hideNames,
            nodes: nodes,
            redraw: true
          })
      }

      case MapActions.MAP_TOGGLE_UNI_COLORS: {
        const isUniColors = !lastState.uniColors
        let nodes: IMapData[] = lastState.nodes
        if (isUniColors) {
          nodes = setAllColorsBlue(nodes)
        } else {
          nodes = csa.csToKlNodes( action.cs.getAllNodes(true) )
        }

        return Object.assign({}, lastState, {
            uniColors: isUniColors,
            nodes: nodes,
            redraw: true
          })
      }

      case MapActions.MAP_CHANGE_FROM_EDGES_FILTER: {
        let filt = action.edgesFilter
        filt = lastState.edgesToFilter <= filt ? lastState.edgesToFilter : filt
        newState = Object.assign({}, lastState, {edgesFromFilter: filt})
        csMap = csa.klToCsAll(newState)
        newState = csa.csToKlAll( action.cs.filterEdges(csMap))
        newState.redraw = true
        return newState
      }

      case MapActions.MAP_CHANGE_TO_EDGES_FILTER: {
        let filt = action.edgesFilter
        filt = lastState.edgesFromFilter >= filt ? lastState.edgesFromFilter : filt
        newState = Object.assign({}, lastState, {edgesToFilter: filt})
        csMap = csa.klToCsAll(newState)
        newState = csa.csToKlAll( action.cs.filterEdges(csMap))
        newState.redraw = true
        return newState
      }

      case MapActions.MAP_SHOW_CORE_NETWORK: {
        const showCoreNetwork = !lastState.showCoreNetwork
        newState = Object.assign({}, lastState, {showCoreNetwork: showCoreNetwork})
        csMap = csa.klToCsAll(newState)
        newState = csa.csToKlAll( action.cs.showCoreNetwork(csMap))
        newState.redraw = true
        return newState
      }

      case MapActions.MAP_CLICK_TO_ISOLATE: {
        csMap = csa.klToCsAll(lastState)
        newState = csa.csToKlAll( action.cs.clickToIsolate(csMap, action.nid.toString()) )
        return Object.assign({}, newState, {redraw: false})
      }

      default: return lastState
    }
  }

  // ================== Local helpers ========================== //

  function hideNodeNames(nodes: IMapData[], clickToIsolateNodeId: string): IMapData[] {
    return _.map(nodes, (n) => {
      if (n.type === 'link') { return n }
      if (clickToIsolateNodeId !== n.id) {
        n.t = `employee-${n.id}`
      }
      return n
    })
  }

  function setAllColorsBlue(nodes: IMapData[]): IMapData[] {
    return _.map(nodes, (n) => {
      if (n.type === 'link') { return n }
      n.c = Constants.COLORSHEX[11]
      return n
    })
  }

  /**
   * For groupings that have heirarchichal strucutre. The
   * only example are the groups, ie "Structure"
   */
  function formatGroupsTree(groups: any[]): IMapGroup[] {
    const ghash = {}
    _.each(groups, (g: any) => { ghash[g.gid] = true} )

    return _.map(groups, (g: any) => {
      const retGroup: IMapGroup = {
        gid: g.gid,
        name: g.name,
        color_id: g.color_id,
        parentId: (ghash[g.parentid] ? g.parentid : null )
      }
      return retGroup
    })
  }

  /**
   * For groupings that have no heirarchichal structure like Gender and Role
   */
  function formatGroupsFlat(colorBy: string, filters: IFilter[]): IMapGroup[] {
    const inx = _.indexOf(Constants.EXPLORE_FILTERS, colorBy)
    const filter = filters[inx]
    return _.map(filter.values, (f: {name: string, active: boolean, color?: number}) => {
      const retGroup: IMapGroup = {
        gid: f.name,
        name: f.name,
        color_id: f.color,
        parentId: null
      }
      return retGroup
    })
  }

  function formatNodes(nodes: any[], selected_eid: string): IMapData[] {
    const scoresArr = _.map(nodes, n => parseInt(n.d, 10))
    const scoresMax = _.max(scoresArr)
    const scoresMin = _.min(scoresArr)

    return _.map(nodes, (n: any) => {
      const gender = n.gender === 'male' ? 'Male' : 'Female'
      const url = n.gender === 'male' ? '/assets/images/male_employee.svg' : '/assets/images/female_employee.svg'
      const score = util.mapToRange(n.d, scoresMin, scoresMax, 1, 4)

      const retNode: IMapData = {
        type: 'node',
        id: n.id,
        t: n.t,
        d: n.d,
        gender: gender,
        group_id: n.group_id,
        group_name: n.gname === undefined ? n.group_name : n.gname,
        job_title: n.job_title_name === undefined ? n.job_title : n.job_title_name,
        rank: n.rank_id === undefined ? n.rank : n.rank_id,
        role: n.role_name === undefined ? n.role : n.role_name,
        office_name: n.office_name,
        u: url,
        color_id: n.color_id,
        e: score
      }

      // Place a halo (round ring) around the root employee
      if (selected_eid === n.id.toString()) {
        retNode['ha0'] = {
          c: 'rgb(16, 123, 118)',
          r: 35,
          w: 10
        }
      }

      return retNode
    })
  }

  /**
   * Format the links as needed by Keylines and the combine service.
   * Color links coming out of an origininating employee - if there is one.
   * @param selected_eid
   */
  function formatLinks(links: any[], selected_eid: string): IMapData[] {
    const retlinks1 = _.map(links, (l: any) => {
      const retLink: IMapData = {
        type: 'link',
        id: `L-${l.id1}-${l.id2}`,
        id1: l.id1,
        id2: l.id2,
        w: Math.max(l.w, 3)
      }
      return retLink
    })

    const retlinks2 = graphService.colorLinks(retlinks1, selected_eid)
    return retlinks2
  }

  /**
   * Recolor the all the nodes according to the colorBy value and set transparency
   * by filters.
   * Also, set gid according to new grouping values.
   */
  function reColorNodes(nodes: IMapData[], filters: IFilter[], colorBy: string): IMapData[] {

    /** Create a flattened version for the filters for easier use */
    const filtersArr = MapNavComponent.filtersFlatten( filters )

    /** Get correct filter */
    const filter = _.find(filters, (f: IFilter) => f.name === colorBy)

    /**
     * Go over all nodes and:
     *   1 - Color each according to colorBy
     *   2 - Change color transparency according to filter state on the node
     */
    const outNodes = _.map(nodes, (n: IMapData) => {
      const nodeColorByValue = getNodeColorByValue(n, colorBy)

      if (colorBy === 'Structure') {
        n.group_id = _.isNil(n.orig_group_id) ? n.group_id : n.orig_group_id
      } else {
        n.orig_group_id = n.group_id
        n.group_id = nodeColorByValue
      }

      const val = _.find(filter.values, (f) => f.name === nodeColorByValue)
      const colorInx: number = _.isNil(val) ? 23 : val.color
      const isFiltered = MapNavComponent.isNodeVisible(filtersArr, n)
      n.c = util.col(colorInx, isFiltered)
      return n
    })

    /** Update the nodes in the combine service */

    return outNodes
  }

  function getNodeColorByValue(n: IMapData, colorBy: string): string {
    switch (colorBy) {
      case 'Structure':
        return n.group_name
      case 'Role':
        return n.role
      case 'Rank':
        return (_.isNil(n.rank) ? 'NA' : n.rank.toString())
      case 'Gender':
        return n.gender
      case 'Office':
        return n.office_name
      case 'Job Title':
        return n.job_title
      case 'Age':
        return (_.isNil(n.age) ? 'NA' : n.age.toString())
      default:
        throw new Error(`Unknown colorBy:  ${colorBy}`)
    }
  }

  /**
   * We create a bunch of "groups" out of a filter because the CombineService only
   * knows how to operated by using groups.
   */
  function createGroupsFromFilter(colorBy: string, filters: IFilter[]): IMapGroup[] {
    const filter: IFilter = _.find(filters, (f: IFilter) => f.name === colorBy)
    if (filter === undefined) { return null }
    if (filter.values.length === 1 && filter.values[0].name === null) {return null}

    const groups: IMapGroup[] = _.map(filter.values, (v: {name: string, active: boolean, color?: number}) => {
      return {
        name: v.name,
        gid: v.name,
        parentId: null,
        color_id: v.color
      }
    })
    return groups
  }
}
