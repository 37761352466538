import { Component, Input } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms'
import {DomSanitizer} from '@angular/platform-browser';

import * as _ from 'lodash'

import {
  IQuest, InteractQuestState
} from '../../../app.state'
import { InteractActions } from '../interact.actions'

@Component({
  selector: 'sa-app-int-test',
  templateUrl: './interact-test.component.html',
  styleUrls: ['./interact-test.component.scss']
})
export class InteractTestComponent {

  @Input() userRole: string
  @Input() set questionnaire (quest) {

    /** The reason for casting to IQuest here is because of a webpack bug which
     *  causes warnings in the console unless we do that. */
    this.questStateReadyToRun   = (<IQuest>quest).state === InteractQuestState.ready
    this.questStateReadyToClose = (<IQuest>quest).state === InteractQuestState.sent
    this.quest = (<IQuest>quest)
  }
  questStateReadyToRun: boolean
  questStateReadyToClose: boolean
  quest = <IQuest>null

  questForm: FormGroup

  testUrl: any

  constructor(
    private interactActions: InteractActions,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer) {}

  onSubmit = (form: any) => {
    const quest = _.cloneDeep(this.quest)
    quest.test_user_name = form['testUserName']
    quest.test_user_email = form['testUserEmail']
    quest.test_user_phone = form['testUserPhone']

    this.interactActions.updateTestParticipant(quest)
  }

  ngOnInit() {
    this.questForm = this.fb.group({
      'testUserName':  [this.quest.test_user_name, Validators.required],
      'testUserPhone': [this.quest.test_user_phone, Validators.required],
      'testUserEmail': [this.quest.test_user_email, Validators.required]
    })

    this.testUrl = this.getTestUserUrl()
  }

  getTestUserUrl = () => {
    if (this.quest.test_user_url === undefined) {return null}
    const url = `${this.quest.test_user_url}&mobile=true&output=embed`
    const ret = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    return ret
  }

  runQuestionnaire = () => {
    if (!this.questStateReadyToRun) {return}
    this.interactActions.runQuestionnaire(this.quest.id)
  }

  closeQUestionnaire = () => {
    if (!this.questStateReadyToClose) {return}
    this.interactActions.closeQuestionnaire(this.quest.id)
  }

  simulateQuestionnaireReplies = () => {
    console.log('In simulateQuestionnaireReplies() for quest id: ', this.quest.id)
    this.interactActions.simulateQuestionnaireReplies(this.quest.id)
  }
}
