﻿import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sa-settings-user-management',
  templateUrl: './settings-user-management.component.html',
  styleUrls: ['./settings-user-management.component.scss', '../settings.component.scss']
})
export class SettingsUserManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
