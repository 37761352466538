import {
  Component,
  OnInit,
  Input,
  Output
 } from '@angular/core'
import { select, NgRedux } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'

import * as _ from 'lodash'

import * as util from '../../services/util.service'
import { getActiveGroupsNoneIsAll } from '../../services/groups.service'
import * as ms from '../../services/map.service'
import { normalizeLinks } from '../../services/graph.service'
import { INode } from './combine.service'
import * as csa from './combine.service.adapter'

import {
  IMapData,
  IMapState,
  IQuest,
  IAppState
} from '../../app.state'

import * as Consts from '../../constants'
import { MapActions } from './map.actions'
import { IMapHandler } from '../../types/map-handler'

const trace = util.traceToggle(false)

/**
 * A data structure that represents the entire map
 */
@Component({
  selector: 'sa-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, IMapHandler {

  @Input() mapRootId: string
  @Input() activeQuestId: number
  @Input() activeQuestionId: number
  @Input() questionnaires: IQuest[]
  @Input() ma: MapActions

  mapData: IMapData[]

  chart: KeyLines.Chart
  waitForChart = true

  contWidth: number
  contHeight: number

  showMapReset = false

  map$: Observable<IMapState>
  mapSubscription: Subscription

  department$: Observable<string>
  questionnaireName$: Observable<string>
  questionTitle$: Observable<string>
  colorBy$: Observable<string>
  filtersStr$: Observable<string>

  @select(['groups', 'groups']) readonly selectedGroups$: Observable<any>
  activeGids: number[] = null
  groupsSubscription: Subscription

  @select(['interact', 'hideNav']) readonly hideNav$: Observable<boolean>

  @select(['global', 'showChart']) readonly showChart$: Observable<boolean>

  makeBigTitle = 'Full view'
  /** Here we remember the settings before going to full screen in order to be ablet to revert */
  mapRefPosition: string
  mapRefTop: string
  mapRefLeft: string
  mapRefWidth: string
  mapRefHeight: string
  mapRefBorder: string
  mapRefZIndex: string

  /**
   * Constructor
   */
  constructor( private ngRedux: NgRedux<IAppState> ) {}

  ngOnInit() {
    this.map$ = this.ngRedux.select([this.mapRootId])
    this.department$ = this.ngRedux.select([this.mapRootId, 'department'])
    this.questionnaireName$ = this.ngRedux.select([this.mapRootId, 'questionnaireName'])
    this.questionTitle$ = this.ngRedux.select([this.mapRootId, 'questionTitle'])
    this.colorBy$ = this.ngRedux.select([this.mapRootId, 'colorBy'])
    this.filtersStr$ = this.ngRedux.select([this.mapRootId, 'filtersStr'])

    /** When data changes - redraw Keylines */
    this.mapSubscription = this.map$.subscribe((map: IMapState) => {
      if (map === undefined) { return }
      this.remap(map)
    })

    /**
     * Listen to groups selection
     * Also, because this subscription is triggered when groups are added then
     * this part of the code is in charge of triggering fetchMapData which
     * loads the map
     */
    this.groupsSubscription = this.selectedGroups$.subscribe((groups) => {

      // This piece of code should be moved to interact.component.ts .
      // For now we handle it from here.
      if (this.mapRootId !== Consts.EXPLORE_INTERACT) {return}
      const gids = getActiveGroupsNoneIsAll(groups)
      if ( !_.isEqual(this.activeGids, gids ) && gids.length > 0 ) {
        this.ma.fetchMapData(this.mapRootId, this.activeQuestionId, gids)
        this.activeGids = gids
      }
    })
  }

  ngOnDestroy() {
    this.mapSubscription.unsubscribe()
  }

  private remap = (map: IMapState) => {
    if (map.nodes === undefined) {return}

    /**
     * The difference is whether we want to redraw the entire map because some nodes
     * were added or removed. Or whether we just want to change node properties, like
     * colors for example.
     */
    if (map.redraw) {
      /** Normalise link widths to a 1-10 scale */
      map.links = normalizeLinks(map.links)

      this.mapData = map.nodes.concat( map.links )
      this.loadChart(this.mapData)
      this.ma.mapRedrawIsDone(this.mapRootId)
    } else {
      if (this.chart === undefined)  { return }
      const newNodes: any[] = _.map(map.nodes, (n: IMapData) => ({id: n.id, id1: n.id1, id2: n.id2, c: n.c, ha0: n.ha0}) )
      const newLinks: any[] = _.map(map.links, (l: IMapData) => ({id: l.id, c: l.c}) )
      const changes = _.concat(newNodes, newLinks  )
      this.chart.setProperties(changes)
    }
  }

  /**
   * Load the keylines chart
   */
  loadChart(mapData: IMapData[]) {
    trace('Trying to loadChart()')
    if (this.chart === undefined)  { return }

    const options: KeyLines.LayoutOptions = {
      animate: true,
      fixed: this.chart.selection(),
      tidy: true,
      fit: true,
      consistent: true
    }

    const chartData: KeyLines.ChartData = {
      type: 'LinkChart',
      items: <any>mapData
    }

    this.chart.load( chartData )
    this.chart.layout('standard', options)
  }

  /**
   * The Keylines callback here. Chart plotting happens here
   */
  klChartReady(chart: KeyLines.Chart) {
    trace('InterfacesComp - klChartReady() - keylines chart is ready')
    this.chart = chart;

    if (this.waitForChart) {
      trace('InterfacesComp - klChartReady() - loading chart')
      this.loadChart(this.mapData)
      this.waitForChart = false
    }
  }

  /**
   * Will receive events for the chart
   */
  klChartEvents(event: any) {
    if (event.name === 'dblclick') {
      this.handleDbClick(event)
      event.preventDefault = true
    }

    if (event.name === 'click') { ms.removeContextMenu() }

    if ( event.name === 'contextmenu' ) {
      this.contextMenuClicked(event)
    }

    if (event.name === 'hover') {
      // console.log('Chart hover event triggered: ', event);
    } else if (event.name === 'delete') {
      // console.log('Chart delete event triggered: ', event);
      event.preventDefault = true
    }
  }

  // ========================== Events handling =============================== //
  private idFromEvent = (e): (number|string) => {
    return e.args[0]
  }

  private handleDbClick = (event) => {
    trace('DbClick() - ', event)
    const nid = this.idFromEvent(event)
    this.ma.handleDbClick(this.mapRootId, nid)
  }

  resetMap = () => {
    this.ma.resetMap(this.mapRootId)
  }

  /**
   * Toggle between full screen size and regular map size
   */
  toggleMakeBig = () => {

    const mapRef = document.getElementById('mapRef')
    if (mapRef.style.position === 'fixed') {
      this.makeBigTitle = 'Full view'
      mapRef.style.position = this.mapRefPosition
      mapRef.style.top = this.mapRefTop
      mapRef.style.left = this.mapRefLeft
      mapRef.style.width = this.mapRefWidth
      mapRef.style.height = this.mapRefHeight
      mapRef.style.border = this.mapRefBorder
      mapRef.style.zIndex = this.mapRefZIndex

    } else {

      this.makeBigTitle = 'Partial view'

      const style = window.getComputedStyle(mapRef)
      this.mapRefPosition = style.getPropertyValue('position')
      this.mapRefTop = style.getPropertyValue('top')
      this.mapRefLeft = style.getPropertyValue('left')
      this.mapRefWidth = style.getPropertyValue('width')
      this.mapRefHeight = style.getPropertyValue('height')
      this.mapRefBorder = style.getPropertyValue('border')
      this.mapRefZIndex = style.getPropertyValue('z-index')

      mapRef.style.position = 'fixed'
      mapRef.style.top = '0'
      mapRef.style.left = '0'
      mapRef.style.width = '100%'
      mapRef.style.height = '100%'
      mapRef.style.border = '1px solid grey'
      mapRef.style.zIndex = '99'
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    },
    50)
  }

  private contextMenuClicked = (event: any) => {
    const nid = event.args[0]
    if (nid === undefined || nid === null) {return}
    const inode: INode = this.ma.getCombineService().nodeFromId(nid)
    const mapDataNode: IMapData = csa.csToKlNode(inode)
    ms.handleContextMenuWithNode(event, mapDataNode, this, {clickToIsolate: true})
  }

  clickToIsolate = (nid: number): void => {
    this.ma.handleClickToIsolate(this.mapRootId, nid)
  }
}
