﻿export const CLIENT_FAKE_LOGIN_MODE = false

export const CLIENT_VERSION = '3.8.12'

/** SELECT HERE 1 URL TO DIRECT THE CLIENT TO */
export const SERVER_REMOTE_URL = [
  {name: 'local-dev', is_selected: false , url: 'http://localhost:3000/'},
  {name: 'onpremise', is_selected: false, url: 'https://stepaheadapp.step-ahead.com/'},
  {name: 'workships-dev', is_selected: false, url: 'https://devapp.herokuapp.com/'},
  {name: 'workships-staging', is_selected: false, url: 'https://stgapp.2stepahead.com/'},
  {name: 'workships-sndcom', is_selected: true, url: 'https://sndcomapp.2stepahead.com/'},
  {name: 'workships-demo', is_selected: false, url: 'https://demoapp.2stepahead.com/'},
  {name: 'workships', is_selected: false, url: 'https://app.2stepahead.com/'}
]

/////////////////////// Colors /////////////////////////
export const INACTIVE_COLOR = '#B2B5B5'
export const DIRECTION_BAD  = '#be0f3a'
export const DIRECTION_GOOD = '#0b6460'

export const COLORSHEX = ['#000000',
  '#fec878', '#ffcd00', '#d8b525', '#fe9a34', '#f15a24', '#c12f02',
  '#9ac4db', '#83ddd2', '#00a99d', '#29abe2', '#0071bc', '#1f5d75',
  '#a6e538', '#83a53a', '#4d7759', '#9fbb9f', '#1c9c5a', '#584b53',
  '#f7bcd0', '#ee6492', '#b41b7e', '#8e292e', '#5133a5', '#9577cb',
]

export const COLORSRGBA = [[0, 0, 0],
  [254, 200, 120], [255, 205, 0],   [216, 181, 37], [254, 154, 52],  [241, 90, 36], [193, 47, 2],
  [154, 196, 219], [131, 221, 210], [0, 169, 157],  [41, 171, 226],  [0, 113, 188], [31, 93, 117],
  [166, 229, 56],  [131, 165, 58],  [77, 119, 89],  [159, 187, 159], [28, 156, 90], [88, 75, 83],
  [247, 188, 208], [238, 100, 146], [180, 27, 126], [142, 41, 46],   [81, 51, 165], [149, 119, 203],
]



///////////////////////// Scores ///////////////////////
export const AGGREGATION_METHOD_GROUPS     = 'groupName'
export const AGGREGATION_METHOD_OFFICES    = 'officeName'
export const AGGREGATION_METHOD_ALGORITHMS = 'algoName'

export const EMAILS_VOLUME   = 'Emails Volume'
export const MEETINGS_VOLUME = 'Time spent in meetings'


export const SNAPSHOT_INTERVAL_TYPES = ['By Month', 'By Quarter', 'By 6 Months', 'By Year']
export const enum IntervalType { NotDefined, Month, Quarter, SixMonths, Year}

export const AGGREGATOR_TYPE_DEPARTMENT = 'Department'
export const AGGREGATOR_TYPE_OFFICES    = 'Offices'
export const AGGREGATOR_TYPE_CAUSES     = 'Causes'
export const AGGREGATOR_TYPES_PRODUCTIVITY  = [AGGREGATOR_TYPE_DEPARTMENT, AGGREGATOR_TYPE_CAUSES, AGGREGATOR_TYPE_OFFICES]
export const AGGREGATOR_TYPES_COLLABORATION = [AGGREGATOR_TYPE_DEPARTMENT, AGGREGATOR_TYPE_OFFICES]

// Regex for alphabet, space and dash chars
export const REGEX_NAMES = /^[a-zA-Z\s\-]*$/;

// Settings constants
export const SETTINGS_PERSONAL_INFO = 'personal';
export const SETTINGS_EDIT_PASSWORD = 'edit_password';
export const SETTINGS_USER_MANAGEMENT = 'management';
export const SETTINGS_SECURITY = 'security';

/** Used for dispaly values bucketed between 1 to 10 */
export const BUCKET_OF_10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const EXPLORE_INTERACT   = 'interactMap'
export const EXPLORE_DYNAMICS   = 'dynamicsMap'
export const EXPLORE_INTERFACES = 'interfacesMap'

/** Other stuff */
export const EXPLORE_FILTERS = ['Role', 'Rank', 'Gender' , 'Office', 'Job Title', 'Age', 'Structure']
