import {
  Component,
  Input,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';

import { InteractActions } from '../interact.actions'
import {
  IQuest
} from '../../../app.state'
import { Subscription } from 'rxjs/Subscription'

@Component({
  selector: 'sa-app-int-questionnaires',
  templateUrl: './interact-questionnaires.component.html',
  styleUrls: ['./interact-questionnaires.component.scss']
})
export class InteractQuestionnairesComponent implements OnInit, OnDestroy {
  @Input() activeQuestId: number
  @Input() questionnaires: IQuest[]
  @Input() requestToAdd: EventEmitter<number>
  sub: Subscription

  highlightedQuestId = -1
  preventClick = false
  timer: any

  constructor(private interactActions: InteractActions) {}

  ngOnInit() {
    this.sub = this.requestToAdd.subscribe(() => {
      console.log('received a request to add ...')
      this.interactActions.createNewQuestionnaire()
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  rowClicked(qid) {
    this.preventClick = false

    this.timer = setTimeout(() => {
      if (!this.preventClick) {
        this.interactActions.questSelected(qid)
      }
    }, 300)
  }

  rowDoubleClicked(qid) {
    clearTimeout(this.timer)
    this.preventClick = true
    this.interactActions.questOpened(qid)
  }

  deleteClicked(qid) {
    if (confirm('Do you really want to delete this Questionnaire?')) {
      this.interactActions.deleteQuestionnaire(qid)
    }
  }

  mouseIsOver(qid) {
    this.highlightedQuestId = qid
  }

  duplicateClicked(qid: number) {
    const popup = document.getElementById('mgmt-quest-dup-background')

    const closeBtn = document.getElementById('mgmt-quest-dup-close')
    closeBtn.addEventListener('click', () => {
      popup.setAttribute('style', 'display: none;')
    })

    const copyBtn = document.getElementById('mgmt-quest-dup-copy')
    copyBtn.addEventListener('click', () => {
      this.interactActions.copyQuestionnaire(qid)
      popup.setAttribute('style', 'display: none;')
    })

    const rerunBtn = document.getElementById('mgmt-quest-dup-rerun')
    rerunBtn.addEventListener('click', () => {
      this.interactActions.rerunQuestionnaire(qid)
      popup.setAttribute('style', 'display: none;')
    })

    popup.setAttribute('style', 'display: initial;')
  }
}
