﻿import { Injectable } from '@angular/core';
import { AppActions } from '../app.actions'

import * as util from './util.service'

const trace = util.traceToggle(false)

@Injectable()
export class LogoutService {

  constructor(private appActions: AppActions) { }

  logout() {
    trace('Loggin out now')
    localStorage.removeItem('jwtToken');
    this.appActions.userSignedOutAction();
  }
}
