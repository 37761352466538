import { InteractQuestState } from '../../app.state'

/**
 * Type guard for InteractQuestState

function isFish(s: string | InteractQuestState): s is InteractQuestState {
  return (<InteractQuestState>pet).swim !== undefined;
} */

export const questionnaireStateToEnum = (s: any): InteractQuestState => {
  let ret = null
  switch (s) {
    case 'created':
      ret = InteractQuestState.created
      break
    case 'delivery_method_ready':
      ret = InteractQuestState.delivery_method_ready
      break
    case 'questions_ready':
      ret = InteractQuestState.questions_ready
      break
    case 'notstarted':
      ret = InteractQuestState.notstarted
      break
    case 'ready':
      ret = InteractQuestState.ready
      break
    case 'sent':
      ret = InteractQuestState.sent
      break
    case 'completed':
      ret = InteractQuestState.completed
      break
    default:
      throw new Error(`Illegal inetract state: ${s}`);
  }
  return ret
}
