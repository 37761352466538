import {
  Component,
  Input,
  ElementRef,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';

import * as _ from 'lodash'

import * as util from '../../../services/util.service'
import { InteractActions } from '../interact.actions'
import {
  IQuest,
  IInteractQuestion
} from '../../../app.state'
import { Subscription } from 'rxjs/Subscription';

const trace = util.traceToggle(false)

/**
 * Rearranged the questions after a question was draged and dropped.
 * @param srcId - Dragged question
 * @param dstId - Question that it was dragged onto
 * @param questions - The list of questions
 */
export function reArrangeQuestions(srcId: number, dstId: number, questions: IInteractQuestion[]): IInteractQuestion[] {
  const len = questions.length
  if (srcId < 0 || srcId >= len || dstId < 0 || dstId >= len) {
    throw new Error(`Illegal index range: srcId: ${srcId}, dstId: ${dstId}`)
  }
  if (srcId === dstId) { return questions }
  if (srcId === 0) { return questions }
  if ( isNaN(srcId) || isNaN(dstId) ) { return questions }

  let res: IInteractQuestion[] = []

  if (srcId > dstId) {
    const keepSlice = _.slice(questions, dstId + 1, srcId)
    const endSlice = _.slice(questions, srcId + 1)
    res = res.concat( _.slice(questions, 0, dstId + 1) )
    res.push( _.remove(questions, (w, i) => i === srcId)[0] )
    res = res.concat( keepSlice )
    if (srcId < (len - 1)) {res = res.concat( endSlice )}
  }

  if (srcId < dstId) {
    res = res.concat( _.slice(questions, 0, srcId) )
    res = res.concat( _.slice(questions, srcId + 1, dstId + 1) )
    const keepSlice = _.slice(questions, dstId + 1)
    res.push( _.remove(questions, (w, i) => i === srcId)[0] )
    res = res.concat( keepSlice )
  }

  /** Fix the questions order field */
  res = _.map(res, (r, i) => {
    r.order = i
    return r
  })
  return res
}

@Component({
  selector: 'sa-app-int-questions',
  templateUrl: './interact-questions.component.html',
  styleUrls: ['./interact-questions.component.scss']
})
export class InteractQuestionsComponent implements OnInit, OnDestroy  {
  @Input() questions: IInteractQuestion[]
  @Input() requestToAdd: EventEmitter<number>
  @Input() questionnaireId: number

  highlightedQuestionId: number
  editingId = -1
  sub: Subscription

  constructor(private actions: InteractActions) {}

  ngOnInit() {
    this.sub = this.requestToAdd.subscribe(() => {
      this.addNewQuestion()
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  mouseIsOver(qid) {
    this.highlightedQuestionId = qid
  }

  /**
   * Save changes as soon as they're made
   */
  loseFocus($e, qid, fieldName) {
    const value = $e.target.innerText
    trace('field: ', fieldName, ', with qid: ', qid, ', changed to: ', value)
    const quest = _.find(this.questions, q => q.id === qid)

    switch (fieldName) {
      case 'title': quest.title = value; break;
      case 'body':  quest.body  = value; break;
      case 'max':   quest.max   = value; break;
      case 'min':   quest.min   = value; break;
    }

    if (!quest.isNewquestion) {
      this.actions.updateQuestion(quest)
    }
  }

  /**
   * Save changes to active state as soon as they're made
   */
  activeChanged(qid, active) {
    trace('active with qid: ', qid, ', has changed to: ', active)
    const quest = _.find(this.questions, q => q.id === qid)
    if (!quest.isNewquestion) {
      this.actions.updateQuestion(quest)
    }
  }

  saveRow(q) {
    trace('Saving question', q)
    if (q.isNewquestion) {
      this.actions.newQuestion(q, this.questionnaireId)
      q.isNewquestion = false
    }
  }

  deleteRow(q) {
    trace('Deleting question: ', q)
    this.actions.deleteQuestion(q.id)
  }

  addNewQuestion = () => {
    console.log('ADDING NEW QUESTION')
    if (this.questions.slice(-1)[0].isNewquestion) {
      return
    }
    const max = _.maxBy(this.questions,
      (q: IInteractQuestion) => {
        return q.order
      }
    )

    this.questions.push({
      title: 'New question',
      body: 'New question',
      order: (max.order + 1),
      min: 1,
      max: 10,
      active: false,
      isNewquestion: true
    })
  }

  getIsActiveId(id) {
    return `isActive-${id}`
  }

  /** Drag and drop */
  drag = (ev) => {
    ev.dataTransfer.setData('dragged-ev', ev.target.id);
  }

  allowDrop = (ev) => {
    ev.preventDefault();
  }

  onDrop = (ev: DragEvent) => {
    ev.preventDefault()
    const srcId = parseInt(
                    ev.dataTransfer
                    .getData('dragged-ev')
                    .split('-')[1], 0)

    const dstId = parseInt(
                    ev.toElement
                    .parentElement
                    .id
                    .split('-')[1], 0)
    this.questions = reArrangeQuestions(srcId, dstId, this.questions)

    const quests = _.map(this.questions, (q: IInteractQuestion) => {
      return {qid: q.id, order: q.order}
    })

    this.actions.reorderQuestions(quests)
  }
  /** End drag and drop */
}
