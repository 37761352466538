import * as _ from 'lodash'

import { IMapData } from '../app.state'
import {capitalize} from './util.service'
import { IMapHandler } from '../types/map-handler';


export const handleContextMenu = (event: any, mapData: IMapData[]) => {
  const args = event.args
  const nodeid = args[0]
  const node: IMapData = _.find(mapData, m => m.id === nodeid)
  handleContextMenuWithNode(event, node)
}

export const handleContextMenuWithNode = (event: any, node: IMapData, map: IMapHandler = null, options = {}) => {
  const args = event.args
  const nodeid = args[0]
  removeContextMenu()
  if (nodeid === null) { return }
  const x = args[1]
  const y = 500 - parseInt(args[2], 0)

  setTimeout(() => { addContextMenu(node,  'node', x, y, map, options) }, 200)
}

const addContextMenu = (node: IMapData, nodeType, x, y, map = null, options) => {

  const klc: HTMLElement = document.getElementById( 'kl' )
  const ch = klc.parentElement

  const cmc: HTMLElement = document.createElement('div');
  cmc.id = 'kl-context-menu-container'
  cmc.style.position = 'relative'

  const cm: HTMLElement = document.createElement('div')
  cm.id = 'kl-context-menu'
  cm.style.position = 'absolute'
  cm.style.maxWidth = '200px'
  cm.style.minWidth = '150px'
  cm.style.backgroundColor = '#edecec'
  cm.style.color = '#515151'
  cm.style.bottom  = `${y}px`
  cm.style.left = `${x}px`
  cm.style.padding = '4px'
  cm.style.borderRadius = '5px'
  cm.style.boxShadow = '1px 1px 7px 1px #51515169'
  cm.style.fontFamily = 'RobotoRegular, sans-serif'

  if (node.id[0] === 'N') {
    cm.innerHTML = employeeContext(node, options)
  } else if (node.id[0] === 'G') {
    cm.innerHTML = groupContext(node, options)
  } else if (parseInt(node.id, 10) >= 100000) {
    cm.innerHTML = groupContext(node, options)
  } else {
    cm.innerHTML = employeeContext(node, options)
  }

  cmc.appendChild(cm)
  ch.appendChild(cmc)

  if (options && options.clickToIsolate === true) {
    const elem = document.getElementById('click-to-isolate')
    elem.addEventListener('click', (e) => {
      map.clickToIsolate(node.id)
      removeContextMenu()
    }, true)
  }
}

const groupContext = (node: IMapData, options): string => {
  const name = `<div style='color: #887319;'>${node.t}</div>`
  const htmlText =
    `${name} \
    <br> \
    <div style='color: black; margin-bottom: 4px;'>Group size: ${node.group_size}</div> \
    <div style='color: black; margin-bottom: 4px;'>Hierarchy size: ${node.hierarchy_size}</div>`
  return htmlText
}

const employeeContext = (node: IMapData, options): string => {
  const name = `<div style='color: #887319;'>${node.t}</div>`
  let clickToIsolate = ''
  if (options && options.clickToIsolate === true) {
    clickToIsolate = `<br>
    <div id='click-to-isolate' style='background-color: #D3D1D2; font-size: 15px; padding-top: 0px; cursor: pointer'>
      <img src='./assets/images/isolate.png' width='30' height='30' style='vertical-align: middle;'> &nbsp; Isolate
    </div>`
  }

  const htmlText =
    `${name} \
    <br> \
    Group: \
    <div style='color: black; margin-bottom: 4px;'>${node.group_name}</div> \
    Office: \
    <div style='color: black; margin-bottom: 4px;'>${node.office_name}</div> \
    Title: \
    <div style='color: black; margin-bottom: 4px;'>${capitalize(node.job_title)}</div>
    ${clickToIsolate}`
  return htmlText
}

export const removeContextMenu = () => {
  const cmc: HTMLElement = document.getElementById( 'kl-context-menu-container' )
  if (cmc !== undefined && cmc !== null) {
    cmc.remove()
  }
}
