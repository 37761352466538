import {
  Component,
  Input,
  OnInit
} from '@angular/core'
import { select } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'

import * as _ from 'lodash'

import {
  IQuest,
  IInteractQuestion,
} from '../../../app.state'
import { InteractActions } from '../interact.actions'
import { getActiveGroupsNoneIsAll } from '../../../services/groups.service'
import * as util from '../../../services/util.service'
import * as Texts from '../../../texts'

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-app-int-coll-nav',
  templateUrl: './coll-nav.component.html',
  styleUrls: ['./coll-nav.component.scss']
})
export class CollpNavComponent implements OnInit {

  subtitle = Texts.INTERACT_COLLABORTION_SUBTITLE

  /** questionnaires */
  questsListOpen = true
  selectedQuestName: string
  questionnairesList: IQuest[]
  questHoverInx = -1
  @Input() activeQuestId: number
  @Input() set questionnaires(v: IQuest[]) {
    this.selectedQuestName = this.updateSelectedQuestName(v, this.activeQuestId)
    this.questionnairesList = v
  }

  /** questions */
  questionsListOpen = false
  selectedQuestionName: string
  questionsList: IInteractQuestion[]
  questionHoverInx = -1
  @Input() activeQuestionId: number
  @Input() set questions(v: IInteractQuestion[]) {
    this.selectedQuestionName = this.updateSelectedQuestionName(v, this.activeQuestionId)
    this.questionsList = _.filter(v, quest => quest.active )
  }

  @select(['groups', 'groups']) readonly selectedGroups$: Observable<any>
  activeGid: number = null
  groupsSubscription: Subscription

  constructor(private interactActions: InteractActions) {}

  ngOnInit() {
    this.groupsSubscription = this.selectedGroups$.subscribe((groups) => {
      const gids = getActiveGroupsNoneIsAll(groups)
      this.activeGid = gids[0]
      if ( !_.isNil(this.activeQuestionId) && !_.isNil(this.activeGid) ) {
        this.interactActions.fetchQuestionnaireData(this.activeQuestionId, this.activeGid)
      }
    })
  }

  updateSelectedQuestName = (quests: IQuest[], qid ): string => {
    if (quests.length === 0) { return 'NA' }
    const selectedQuest: IQuest =
      _.find(quests, (q: IQuest) => q.id === qid)
    if (this.activeQuestId === -1 ||
        selectedQuest === undefined) {
      return quests[0].name
    }
    return selectedQuest.name
  }

  updateSelectedQuestionName = (quests: IInteractQuestion[], qid ): string => {
    if (quests.length === 0) { return 'NA' }
    const selectedQuestion: IInteractQuestion =
      _.find(quests, (q: IInteractQuestion) => q.id === qid)
    if (this.activeQuestionId === -1 ||
        selectedQuestion === undefined) {
      return quests[0].title
    }
    return selectedQuestion.title
  }

  /////////////////// Questionnaires ////////////////////////////////////
  isQuestSelected(qid) {
    return qid === this.activeQuestId
  }

  isQuestHover(inx) {
    return inx === this.questHoverInx
  }

  questHoverIn(inx: number) {
    this.questHoverInx = inx
  }
  questHoverOut() {
    this.questHoverInx = -1
  }

  questClicked(qid) {
    this.activeQuestId = qid
    this.interactActions.questSelected(qid)
  }

  /////////////////// Questions /////////////////////////////////////////
  isQuestionSelected(qid) {
    return qid === this.activeQuestionId
  }

  isQuestionHover(inx) {
    return inx === this.questionHoverInx
  }

  questionHoverIn(inx: number) {
    this.questionHoverInx = inx
  }
  questionHoverOut() {
    this.questionHoverInx = -1
  }

  questionClicked(qid) {
    this.activeQuestionId = qid
    if ( !_.isNil(this.activeQuestionId) && !_.isNil(this.activeGid) ) {
      this.interactActions.fetchQuestionnaireData(this.activeQuestionId, this.activeGid)
    }
  }
}
